import React, { useContext, useEffect } from 'react';
import AppBar from '../ui/navigation/AppBar.js'
import SideBarMenu from '../ui/navigation/SideBarMenu.js'
import Paper from '../ui/surfaces/Paper.js'
import DropDownMenu from '../ui/DropDownMenu.js';
import { ReactComponent as AccountIcon } from '../ui/icons/svg/user-solid-circle.svg';
import { ReactComponent as MenuIcon } from '../ui/icons/svg/menu.svg';
import { IsScreen } from '../ui/screens.js'
// import FilterBar from '../ui/FilterBar.js'
// import SessionContext from '../auth/SessionContext.js'
import { SessionContext } from 'kalenis-portal-frontend-main'

// import AppContext from '../model/context/AppContext'
// import AppContext from 'kalenis-portal-frontend-main'

import {observer} from 'mobx-react-lite'
import {

    useHistory

} from "react-router-dom";



const Header = observer(function Header(props){

    
    
    
    const session = useContext(SessionContext)
    // const app_context = useContext(AppContext)
    // const filterable = app_context.active_screen.filterable
    
    const [anchorEl, setAnchorEl] = React.useState(false);

    const openAccountMenu = event => {
        setAnchorEl(event.currentTarget);

    };
    const closeAccountMenu = () => {
        
        setAnchorEl(false);
    };

    const logout = () => {
        
        session.logout()
    };

    // const search_active = session.search && session.search.active
    
    // const screen = app_context.active_screen

    // const search_active = screen.filterable
    const search_active = false;


    return (
        <AppBar menuWidth={props.menuWidth}>

            <DropDownMenu
                id="account-menu"
                anchor={anchorEl}
                // keepMounted
                options={[
                    {label:"Preferencias", onClick:logout},
                    {label:"Cerrar Sesión", onClick:logout}
                ]}
                open={Boolean(anchorEl)}
                onClose={closeAccountMenu}
            >

                {/* <div onClick={logout}>Cerrar Sesión</div> */}
            </DropDownMenu>
            
            <div style={{minHeight:'64px'}} className="flex items-center justify-between pl-4 pr-2 md:pl-6 md:pr-0">
                
                    
                    
                    {props.menuWidth === 0 ?
                    <MenuIcon style={{ width: '1em', height: '1em' }} onClick={(e)=>props.setMenuWidth(240)} className="fill-current text-contrast-primary text-2xl xs:hidden cursor-pointer w-4 h-4 mr-6" />
                    :
                    <MenuIcon style={{ width: '1em', height: '1em' }} onClick={props.setMobileOpen} className="fill-current text-contrast-primary text-2xl md:hidden cursor-pointer w-4 h-4 mr-6" />        
                    }
                    


                {/* {filterable ?


                    <FilterBar />
                    :
                    ""
                } */}
                
                <div className="flex justify-end flex-grow">

                    <AccountIcon style={{ width: '1em', height: '1em' }} onClick={openAccountMenu} className="fill-current text-contrast-primary text-2xl cursor-pointer w-4 h-4 mr-1 md:mr-4 ml-2" />


                </div>

            </div>
        </AppBar>
    )

});

const Main = observer(function Main(props) {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [menuWidth, setMenuWidth] = React.useState(240);
    
    const history = useHistory();
    const connection = useContext(SessionContext)
    const session_status = connection.status

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        if (!session_status) {
            history.push('/login')
        }

// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session_status]);

    const [calcHeight, setCalcHeight] = React.useState(0);
  
    //change height size effect
    useEffect(() => {
        if (window.innerHeight != calcHeight) {
            setCalcHeight(window.innerHeight - 100);
        }
    }, [window.innerHeight]);


    const drawerClass =  mobileOpen ? " fixed z-40  p-0 border border-gray-300 ":" fixed z-40 border border-gray-300 hidden md:flex p-0 "
    
    
    // const menu_options = props.routes ? [...props.routes].filter(function(option){return option.visible_menu === true}):props.routes
    
    
    return (
        <div className="flex">
            {/* <CssBaseline /> */}
            <Header menuWidth={menuWidth} setMenuWidth={setMenuWidth} setMobileOpen={handleDrawerToggle} />
            <Paper shadowClass="shadow-md" style={{ padding: 0, width: mobileOpen ? 240:menuWidth, display:!menuWidth ? 'none':'' }} className={drawerClass}>

                <nav className="h-screen w-full z-50 left-0 top-0 bg-white">

                    <SideBarMenu setMenuWidth={setMenuWidth}  setMobileOpen={handleDrawerToggle} calcHeight={calcHeight}/>

                   
                </nav>
            </Paper>

            
                <main style={{ marginLeft: IsScreen({type:'sm',down:true}) ? '0px':menuWidth }} className="flex-grow right-0 sm:p-2 mt-16">
                
                    {props.children}
                </main>

            

        </div>
    )
    
})



export default Main;