import React from 'react';
import Field from '../../ui/fields/Field';
import CheckBox from '../../ui/fields/CheckBox';
import FormButton from '../../ui/buttons/FormButton.js'
import HtmlView from '../html/HtmlView.js';
import IconButton from '../../ui/buttons/IconButton.js';
import { ReactComponent as EditIcon } from '../../ui/icons/svg/edit-pencil.svg';
import Modal from '../../ui/Modal.js'
import Portal from '../../common/Portal.js'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx';

const RENDER_FIELDS = ['binary','formula']

const Cell = observer(function Cell(props) {

    const { field,
        rowIndex,
        columnIndex,
        focusCell,
        activeCell,
        cell_key,
        record,
        focusInGrid,
        style,
        screen,
        isSpreadsheet } = props;
    const isSelected = screen.is_selected(record.id)
    const state_attrs = field.get_state_attrs(record)
    const readonly = state_attrs ? state_attrs.readonly : field.readonly
    const error = record._errors[field.name]
    const field_style = field.get_style(record)
    const cell_style = {...field_style,...style}
    const containerRefModal = React.useRef(null);
    const [posX, setPosX] = React.useState(null);
    const [posY, setPosY] = React.useState(null);
    const [showPreviewModal, setShowPreviewModal] = React.useState(false);
    const [showEditModal, setShowEditModal] = React.useState(false);
    

    const getRowClass = (rowIndex, key) => {

        let rowClass = isSpreadsheet ? 'TableEvenRow editableGrid' : rowIndex % 2 === 0 ? "TableEvenRow" : "TableOddRow"
        if (readonly) {
            rowClass = clsx(rowClass, "border border-gray-300 bg-gray-200")
        }
        if (isSelected) {
            rowClass = clsx(rowClass, "bg-light-selected rowHiglighted")
        }
        if (error) {
            rowClass = clsx(rowClass, "bg-red-200")
        }
        if (activeCell) {
            rowClass = clsx(rowClass, 'focusedCell')
        }


        return rowClass;
    }
    const rowClass = getRowClass(rowIndex, cell_key)

    const getValue = () => {

        //TODO => Booleans
        
        if (field.type === 'button') {
            return (
                <div className="leading-none pt-2 text-center">
                    <FormButton state_attrs={state_attrs} variant="list" color="primary" onClick={(e) => field.action.execute([record], false)}>
                        {field.description}
                    </ FormButton>
                </div>
            )
        }
        else if (RENDER_FIELDS.includes(field.type)) {
            return (<Field
                key={cell_key}
                field={field}
                record={record}
                noWrap
                autoFocus={false}
                handleInnerValue={false}
                focusInGrid={focusInGrid}
            />)
        }
        else if (field.type === "html" || field.type === "text"){
                return (
                    <div className="flex justify-between align-middle">
                        <div className="w-40">
                            {field.type === "html" ?
                                <HtmlView fromCell={true} htmlValue={field.get_string_value(record)} />
                                :
                                field.get_string_value(record)
                            }
                        </div>
                        

                        <IconButton
                            onClick={e => onClickEditButton(e)}
                            className="justify-end px-2 my-1 h-8 w-6"
                            color="transparent"
                        >
                            <EditIcon className="fill-current text-primary h-3 w-3 z-40" />
                        </IconButton>

                    </div>
                )
        }
        else {
            return field.get_string_value(record)
        }

    }

    const getField = () => {
        if(field.type === "html" || field.type === "text"){
            return (
                <div className="flex justify-between align-middle">
                    {field.type === "html" ?
                        <div className="w-40">
                            <HtmlView fromCell={true} htmlValue={field.get_string_value(record)} />
                        </div>
                        : 
                        <Field
                            // inputRef={(ch) => spreadRefs[key] = ch}
                            key={cell_key}
                            field={field}
                            record={record}
                            noWrap
                            autoFocus={true}
                            handleInnerValue={false}
                            focusInGrid={focusInGrid}
                        />
                    }

                    <IconButton
                        onClick={e => onClickEditButton(e)}
                        className="justify-end px-2 my-1 h-8 w-6"
                        color="transparent"
                    >
                        <EditIcon className="fill-current text-primary h-3 w-3 z-40" />
                    </IconButton>

                </div>
            )

        } else {


            return (

            <Field

                // inputRef={(ch) => spreadRefs[key] = ch}
                key={cell_key}
                field={field}
                record={record}
                noWrap
                autoFocus={true}
                handleInnerValue={false}
                focusInGrid={focusInGrid}
            />
        )

        }
    }

    function onClickEditButton(e){
        e.stopPropagation();
        setShowPreviewModal(false);
        setShowEditModal(true);

    }

    function onClickSaveButton(e){
        e.stopPropagation();
        props.saveRecord()
        setShowPreviewModal(false);
        setShowEditModal(false);

    }

    const previewModal = () => {
        return (
                <Modal
                    style=
                        {{
                            left: posX,
                            top: posY,
                            width:'400px',
                            height: '206px'
                        }}
                    modalType="preview"
                    open={showPreviewModal}
                    containerRefModal={containerRefModal}
                    showModalPreview={setShowPreviewModal}
                    paper={true}
                    target="portal_preview"
                >
                    <div className="w-full h-40 overflow-x-hidden overflow-y-scroll">
                        <Field
                            // inputRef={(ch) => spreadRefs[key] = ch}
                            key={cell_key}
                            field={field}
                            record={record}
                            noWrap
                            autoFocus={true}
                            handleInnerValue={false}
                            focusInGrid={focusInGrid}
                            previewEditor={true}
                        />
                    </div>

                    <div className="flex justify-center">
                        <FormButton
                            state_attrs={state_attrs}
                            variant="list"
                            color="transparent"
                            className=" mr-1 my-2"
                            onClick={(e) => onClickEditButton(e)}
                        >
                            Editar
                        </ FormButton>

                        <FormButton
                            state_attrs={state_attrs}
                            variant="list"
                            color="transparent"
                            className=" ml-1 my-2"
                            onClick={() => setShowPreviewModal(false)}
                        >
                            Cerrar
                        </ FormButton>

                    </div>

                </Modal>
            )

    }

    const editModal = () => {
        if(field.type === "text"){
            return (
                <Modal
                    style={{height: '438px', width: '532px'}}
                    modalType="edition"
                    open={showEditModal}
                    containerRefModal={containerRefModal}
                    showModalEdit={setShowEditModal}
                    paper={true}
                    saveRecord={props.saveRecord}
                >
                    <div 
                        style={{
                            height: '400px',
                            paddingLeft: '14px',
                            paddingRight: '14px',
                            paddingTop: '14px',
                            paddingBottom: '8px',
                        }}
                        className="w-full"
                    >
                        <Field
                            // inputRef={(ch) => spreadRefs[key] = ch}
                            key={cell_key}
                            field={field}
                            record={record}
                            noWrap
                            autoFocus={true}
                            handleInnerValue={false}
                            modalClass=" h-full resize-none"
                            focusInGrid={focusInGrid}
                        />
                    </div>

                    <div className="flex justify-center">
                        <FormButton
                            state_attrs={state_attrs}
                            variant="list"
                            color="transparent"
                            className=" mr-1"
                            onClick={(e) => onClickSaveButton(e)}
                        >
                            Guardar
                        </ FormButton>

                        <FormButton
                            state_attrs={state_attrs}
                            variant="list"
                            color="transparent"
                            className=" ml-1"
                            onClick={() => setShowEditModal(false)}
                        >
                            Cerrar
                        </ FormButton>
                    </div>

                </Modal>
            )

        } else {
            return (
                <Modal
                    modalType="edition"
                    open={showEditModal}
                    containerRefModal={containerRefModal}
                    showModalEdit={setShowEditModal}
                    paper={true}
                    saveRecord={props.saveRecord}
                >
                    <div 
                        style={{height: '400px'}}
                        className="w-full overflow-x-hidden overflow-y-scroll"
                    >
                        <Field
                            // inputRef={(ch) => spreadRefs[key] = ch}
                            key={cell_key}
                            field={field}
                            record={record}
                            noWrap
                            autoFocus={true}
                            handleInnerValue={false}
                            focusInGrid={focusInGrid}
                        />
                    </div>

                    <div className="flex justify-center">
                        <FormButton
                            state_attrs={state_attrs}
                            variant="list"
                            color="transparent"
                            className=" mr-1 my-2"
                            onClick={(e) => onClickSaveButton(e)}
                        >
                            Guardar
                        </ FormButton>

                        <FormButton
                            state_attrs={state_attrs}
                            variant="list"
                            color="transparent"
                            className=" ml-1 my-2"
                            onClick={() => setShowEditModal(false)}
                        >
                            Cerrar
                        </ FormButton>
                    </div>

                </Modal>
            )

        }

    }

    function onClickCell(e){
        if(field.type === "html" || field.type === "text"){
            if(field.type === "text"){
                setShowEditModal(true)
            } else {
                const gridWidth = parseInt(document.getElementById("body_grid").style.width)
                if(e.clientX > gridWidth){
                    setPosX(!(e.clientX - gridWidth > 60) ? e.clientX - 200 : gridWidth - 20);
                    setPosY(e.clientY);
                } else if(gridWidth - e.clientX > 15 && gridWidth - e.clientX < 180){
                    setPosX((gridWidth - e.clientX > 15) ? e.clientX - 200 : gridWidth - 20);
                    setPosY(e.clientY);
                } else {
                    setPosX(e.clientX);
                    setPosY(e.clientY);
                }
                setShowPreviewModal(true)
            }
        }

        if(isSpreadsheet){
            focusCell({
                key: cell_key,
                columnIndex: columnIndex,
                rowIndex: rowIndex,
                event: e,
            })
        }
        else{
            let toogle = true
            let range = false
            
            if (e.nativeEvent.shiftKey ||
                e.nativeEvent.metaKey ||
                e.nativeEvent.ctrlKey) {
      
                toogle = false
                if(e.nativeEvent.shiftKey){
                    range = true
                }
              }
            screen.changeSelection({index:rowIndex,toogle:toogle,range:range})
        }
    }

    function onDoubleClick(e){
        e.preventDefault()
        e.stopPropagation()
        
        if(!isSpreadsheet){
            if(screen.default_action){
            
                screen.default_action.execute([record])
            }
        }
        
    }

    return (
        <>
        <div
            className={rowClass}
            tabIndex={"-1"}
            onClick={onClickCell}
            onDoubleClick={onDoubleClick}
            style={cell_style}>

            {!activeCell ? getValue() : getField()}

        </div>

        {showPreviewModal && previewModal()}
        {showEditModal && editModal()}
        </>
    )


});

export default Cell;