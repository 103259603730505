import { makeAutoObservable, autorun, runInAction } from "mobx"
import { downloadFile, evalExpr, validateNumber } from '../common'
import { Record } from "./Record"
// import uuid from "node-uuid"
// TODO: Review record vs selected_records => needs to be unified
export class Action {
    default = false
    default_save = false
    description = ""
    id = ""
    key_fields = ""
    modal = null
    multiple_records = false
    name = ""
    show_button = false
    target_title = ""
    target_view = ""
    type = ""
    visible_on_select = false
    return_action = null
    report_ids = ""
    report_data = ""
    target_view_definition = {}
    pre_action = ""
    post_action = ""
    custom_method_op = ""
    custom_validation = false
    requires_confirmation = false
    confirmation_message = false
    show_success_message = false
    success_message_type = ""
    success_message = ""
    invisible = false
    custom_path = ''


    constructor(attributes, screen) {
        makeAutoObservable(this)
        this.screen = screen
        this.default = attributes.default || false
        this.default_save = attributes.default_save || false
        this.description = attributes.description || ""
        this.id = attributes.id || ""
        this.key_fields = attributes.key_fields || ""
        this.modal = attributes.modal || null
        this.multiple_records = attributes.multiple_records || false
        this.name = attributes.name || ""
        this.show_button = attributes.show_button || false
        this.target_title = attributes.target_title || ""
        this.type = attributes.type || ""
        this.visible_on_select = attributes.visible_on_select || false
        this.target_view = attributes.target_view
        this.target_view_definition = attributes.target_view_definition
        this.return_action = attributes.return_action
        this.report_ids = attributes.report_ids
        this.report_data = attributes.report_data
        this.pre_action = attributes.pre_action
        this.post_action = attributes.post_action
        this.custom_method_op = attributes.custom_method_op
        this.custom_validation = attributes.validation_function ? new Function('records', attributes.validation_function) : false
        this.requires_confirmation = attributes.requires_confirmation
        this.confirmation_message = attributes.confirmation_message
        this.show_success_message = attributes.show_success_message
        this.success_message_type = attributes.success_message_type
        this.success_message = attributes.success_message
        this.include_current_search = attributes.include_current_search
        this.invisible = attributes.invisible ? attributes.invisible:false
        this.custom_path = attributes.custom_path

    


    }

    getKeyFields() {
        return this.key_fields.split(";")
    }

    /**
 * Map the new saved values to records.
 * @param {array} old_records - array of Records.
 * @param {array} new_records - array of objects with the shape: {record_id:new_values}
 * @return {array} Array of Records with new values
 */
    mapNewRecords(old_records, new_records = []) {

        let recs = old_records.map(function (r) {
            let attrs = new_records.find(n => Object.keys(n)[0] == r.id)
            let new_record = new Record(attrs[r.id], this.screen, this.group)
            this.screen.data.replaceRecord(r, new_record)
            return new_record

        }.bind(this))

        return recs
    }

    /**
* Save the records.
* @param {array} records - array of Records to be saved.
* @return {array} Array of Records with new values and ids
*/
    async executeCreateUpdate(records, async_reload) {
        let prm = []
        records.forEach(function (rec) {
            prm.push(rec.save(this))
        }.bind(this))
        //HERE => Map the old values to new ones, avoiding the need to map everywhere
        prm = await Promise.all(prm)
        if (Array.isArray(prm) && prm.some(r => r === false)) {
            prm = false
        }
        else {
            prm = this.mapNewRecords(records, prm)
        }

        return prm


        // return Promise.all(prm)


    }
    /**
* Delete records.
* @param {array} records - array of Records to be deleted.
* @return {void} 
*/

    async executeDeleteRecord(records) {
        const selected_records = records ? records : this.screen.selected_records;
        let args = {}
        let res = {}
        args['ids'] = selected_records.filter(function(r){return r.id>0}).map(function(rec){return rec.id})
        args['view'] = this.screen.id
        if(args['ids'].length){
            const abortController = new AbortController()
            res = await this.screen.connection.dispatch('POST', '/delete', args, false, false, true, abortController)
            
        }
        if(args['ids'].length && res['result'] !== null){
            return false
        }
        
        this.screen.data.removeRecords(selected_records)
        
        return res

        // request('POST', '/delete', args, false, false, true, abortController)
    }

    /**
*  Navigates to other route
    If key_fields, uses screen selected_records
* @return {void} ""
*/
    async executeLinkAction(records, initialize_callback) {

        const selected_records = records ? records : this.screen.selected_records;

        if (this.visible_on_select && selected_records.length < 1) {
            this.screen.notifications.addSnack(
                { message: "Seleccione un registro para ejecutar esta acción", level: 'error' }
            )
            return false
        }
        if (!this.multiple_records && selected_records.length > 1) {
            this.screen.notifications.addSnack(
                { message: "Seleccione sólo un registro para ejecutar esta acción", level: 'error' }
            )
            return false
        }

        const field_value = (records, fname) =>{
            let val;
            if(records.length > 1){
                val = records.map(function(record){return record.get_value(fname)})
            }
            else{
                val = records[0].get_value(fname)
                if(this.multiple_records){
                    val = [val]
                }
            }
            return val;
        }

        const target_title = (records, expression) =>{
            let title = ""
            if(records.length > 1){
                
                let variables = records.map(function(record){return evalExpr(record._values, expression).variables || ""})
                let to_replace = expression.match(/{([^}]+)}/g)
                title = expression
                to_replace.forEach(function(field){
                    title = title.replace(field,"")
                })
                title += variables.map(function(variable){
                    let text = ""
                    for(let k in variable){
                        text+= variable[k].toString()
                    }
                    return text
                }).toString()
                
            }
            else{
                title = evalExpr(records[0]._values, expression).str || ""
            }
            return title;
        }

        const key_fields = this.getKeyFields()
        
        
        const search = { current_search: [], action_params: [], action_id: this.id }
        if(this.include_current_search){
            search.current_search = this.screen.current_search
        }
        
        if (key_fields) {
            key_fields.forEach(function (field) {

                if (field.split('.').length > 1) {
                    let spl_field = field.split('.')
                    //TODO: Add support for browseObject

                    throw new Error("Invalid Key field " + field);
                    
                }
                else {
                    if (field) {
                        
                        search.action_params.push([field, '=', field_value(selected_records,field)])
                    }

                }


            })
        }

        search['_action_id'] = { value: this.id }
        let route = this.screen.connection.getRouteById(this.target_view)
        
        if (!route) {
            this.screen.notifications.addSnack(
                { message: "No tiene acceso a esta vista.", level: 'error' }
            )
            return false
        }
        



        let route_state = {}

        if (this.target_title && selected_records.length) {
            route_state[this.id] = {}
            const title = target_title(selected_records,this.target_title)
            
            route_state[this.id]['title'] = title

        }
        if (this.modal) {
            // view, history, initial_search, route_state = {}, set_active = true, is_modal = false, parent = false, initialize_fields = true, initialize_actions = true, initialize_data=true, initialize_callback=false, fileHandler=false
            this.screen.modal_childs.addScreen(this.target_view_definition, false, search, route_state, true, true, this.screen, true, true, true,initialize_callback)
        }
        else {
            // if the route has a path, means its web environment. 
            const path = route.path ? route.path.concat(this.screen.connection.createUrl(search)):route.name
            const params = route.path ? route_state:search
            

            this.screen.navigate(path, params)


        }

        return true


    }

    async executeExportData() {

        const abortController = new AbortController();
        let params = {}
        const notification = this.screen.notifications.addSnack(
            { message: "Exportación en curso...", persistant: true }
        )
        params['search'] = this.screen.current_search
        params['action_params'] = this.screen.action_params
        params['action_id'] = this.id
        params['origin_action_id'] = this.screen.action_id ? this.screen.action_id : ''
        let res = await this.screen.connection.dispatch('GET', '/export_data', params, false, false, true, abortController)

        downloadFile(URL.createObjectURL(res), this.screen.title)
        notification.remove()
        this.screen.notifications.addSnack(
            { message: "Descarga Completa" }
        )


        return res

    }

    async executeReportAction(records) {
        const notification = this.screen.notifications.addSnack(
            { message: "Reporte en progreso...", persistant: true }
        )
        const selected_records = records ? records : this.screen.selected_records;
        let args = {}
        args.action_id = this.id


        args.ids = []
        selected_records.forEach(function (record) {
            let ids_value = record.get_value(this.report_ids)
            if (Array.isArray(ids_value)) {
                ids_value.forEach(function (id) {
                    args.ids.push(id)
                })
            }
            else {
                args.ids.push(ids_value)
            }

        }.bind(this))

        //get report_data
        args['data'] = {}
        selected_records.forEach(function (r) {
            let expr = evalExpr({ ids: args.ids, ...r._values }, this.report_data.substring(1, this.report_data.length - 1))
            if (expr.str) {

                args['data'] = JSON.parse('{' + expr.str + '}')
            }
        }.bind(this))


        const abortController = new AbortController();

        let res = await this.screen.connection.dispatch('GET', '/report_action', args, false, false, true, abortController)
        
        if(this.screen.fileHandler){
            notification.remove()
            return this.screen.fileHandler(res)
        }
        else{
            URL.createObjectURL(res)
            notification.remove()
    
            return window.open(URL.createObjectURL(res), '_blank')
        }
        

    }

    /**
* Executes a classmethod on the model. If the view has a default_save action, 
* the record is saved before classmethod execution. If this operation fails,the classmethod is not executed.
* @param {array} records - Array of records. If undefined, the current screen selected records will be used
* @return {array} Array of Records with new values after classmethod execution
*/
    async executeClassMethod(records) {
        let selected_records = records ? records : this.screen.selected_records;
        let new_recs = selected_records
        let save_action = this.screen.default_save_action
        let args = {}
        args.values = {}
        args['action_id'] = this.id
        const abortController = new AbortController();
        if (save_action) {
            new_recs = await this.executeCreateUpdate(selected_records)
            if (Array.isArray(new_recs) && new_recs.some(r => r === false)) {
                return false
            }

        }
        if (!new_recs) {
            return false
        }
        const key_fields = this.getKeyFields()
        // selected_records = this.mapNewRecords(selected_records, new_recs)

        key_fields.forEach(function (fname) {
            if (["ids", "records"].includes(fname)) {
                args.values[fname] = new_recs.map(function (r) { return r.id })
            }
        })

        let res = await this.screen.connection.dispatch('POST', '/classmethod', args, false, false, false, abortController)
        
        if(!res){
            return false
        }
        return this.mapNewRecords(new_recs, res)





    }

    async executeCustomMethod(records) {
        let args = {}
        let selected_records = records ? records : this.screen.selected_records;
        args['action_id'] = this.id
        args['data'] = selected_records.map(function (record) {
            if (record.validate()) {
                return record.get_all_values()
            }
            else {
                return false
            }

        })
        
        //return false if any record is not valid
        if (Array.isArray(args['data']) && args['data'].some(r => r === false)) {
            return false
        }

        const abortController = new AbortController();
        let action_result = await this.screen.connection.dispatch(this.custom_method_op, '/custom_action', args, false, false, false, abortController)
        return action_result

    }

    async executeSendMail(records){
        
        const args = {}
        args['action_id'] = this.id
        args['data'] = records.map(function (record) {
            if (record.validate()) {
                return record.get_all_values()
            }
            else {
                return false
            }

        })
        if (Array.isArray(args['data']) && args['data'].some(r => r === false)) {
            return false
        }
        
        const abortController = new AbortController();
        let action_result = await this.screen.connection.dispatch('POST', '/send_mail', args, false, false, false, abortController)
        return action_result


    }

    async save_active_record(records) {
        let selected_records = records ? records : this.screen.selected_records;
        let save_action = this.screen.default_save_action
        return new Error("Save Active Record: Implementation Pending")

    }

    validate_records(records) {
        let valid = true
        let recs = records.map(function (record) {
            return record.validate()

        })
        //return false if any record is not valid
        if (Array.isArray(recs) && recs.some(r => r === false)) {
            valid = false
        }
        return valid
    }

    /**
* Saves the parent record and update default values (based on parent) on the given records.
* The parent record is only reloaded if a linked return action is attached to save.
* @param {array} records - Array of records. If undefined, the current screen selected records will be used
* @return {array} new (saved) parent records or false
*/
    async save_parent_record(records) {
        let save_action = this.screen.parent.default_save_action
        if (!save_action) {
            return false
        }
        let selected_records = records ? records : this.screen.selected_records;
        let new_recs = selected_records

        new_recs = await save_action.execute([this.screen.parent.active_record])

        records.forEach(function (record) {
            //Get the parent defaults again, with the saved parent
            const parent_defaults = record.get_parent_defaults(new_recs[0])
            record.set_values(parent_defaults)
        })
        return new_recs

    }

    async executePreAction(records) {

        if (!this.validate_records(records)) {
            return false
        }

        if (this.pre_action === 'save') {
            return await this.save_active_record(records)
        }
        else if (this.pre_action === 'save_parent') {
            return await this.save_parent_record(records)
        }


    }


    async execute_custom_validation(records){
        let valid = false;
        try{
            valid = this.custom_validation(records)
        }
        catch(e){
            let message = "A problem ocurred with the custom validation method on action: " + this.name + '  =>  '
            message+=(e.toString())
            this.screen.notifications.addSnack(
                { message: message, level: 'error',timeout:5000 }
            )
        }
        

        return valid;
    }

    showSuccessMessage(){
        
        if(this.success_message_type === 'snack'){
            
            this.screen.notifications.addSnack(
                { message: this.success_message, level: 'sucessfull',timeout:5000 }
            )
        }
        else if(this.success_message_type == 'dialog'){
            this.screen.notifications.addDialog({
                'message':this.success_message, 
                'html':true,
                'actions':[]
            }, 
                true)  
        }
    }

    /**
    * Execute action
    * @param {array}  records - Array with records related to the action. 
    * @param {boolean}  async_reload - --- 
     
    * @return {void} ""
    */
//    TODO: Improve records validation
    async execute(records, async_reload,confirmed=false, executed=false, initialize_callback=false) {

        let res = ""
        records = records ? records : this.screen.selected_records;
        if (!this.multiple_records && records.length > 1) {
            this.screen.notifications.addSnack(
                { message: "Seleccione sólo un registro para ejecutar esta acción", level: 'error' }
            )
            return false
        }
        if (this.screen.actions_map.hasOwnProperty(this.name) && !executed) {
            if (this.validate_records(records)) {
                return this.screen.actions_map[this.name](records)
            }

        }
        if(this.custom_validation){
            if(this.validate_records(records)){
                let is_valid = await this.execute_custom_validation(records)
                if(!is_valid){
                    return false
                }
            }
            else{
                return false
            }

           
        }

        if(this.requires_confirmation && !confirmed){
            this.screen.notifications.addDialog({
                'message':this.confirmation_message, 
                'html':true,
                'actions':[
                    {'name':'OK', 'color':'primary', 'callback':()=>{
                        this.execute(records,false,true)
                        return true
                    }}
                ]}, 
                true)    
            return false
        }

        if (this.pre_action) {
            await this.executePreAction(records)

        }

        switch (this.type) {
            case 'create_update':
                res = await this.executeCreateUpdate(records, async_reload);
                break;
            case 'link':
                res = await this.executeLinkAction(records, initialize_callback)
                break;
            case 'export_data':
                res = await this.executeExportData()
                break;
            case 'report':
                res = await this.executeReportAction(records)
                break;
            case 'classmethod':
                res = await this.executeClassMethod(records)
                break;
            case 'custom_method':
                res = await this.executeCustomMethod(records)
                break;
            case 'delete_record':
                res = await this.executeDeleteRecord(records)
                break;
            case 'send_email':
                res = await this.executeSendMail(records)
                break;
        }

        // //Some action is not executed, example: record is not valid, avoid return actions
        // if (Array.isArray(res) && res.some(r => r === false)) {
        //     return false
        // }
        
        
        if (res && this.post_action) {
            if (this.post_action === 'close_reload') {
                this.screen.reload_parent()
                this.screen.group.removeScreen(this.screen.id)
                
            }
            else if (this.post_action === 'reload_parent') {
                this.screen.reload_parent()
            }
            else if (this.post_action === 'update_record') {
                
                //TODO: REVIEW
                
                records.forEach(function (r) {
                    r.set_values(res[r.id])
                })
            }
        }
        if(res && this.show_success_message){
            this.showSuccessMessage()
        }
        if (res && this.return_action) {
            let return_action = new Action(this.return_action, this.screen)
            // let recs = this.mapNewRecords(records, res)

            return_action.execute(res).then(function (a) {
                if (this.type === 'classmethod') {

                    this.screen.reload_field_childs()
                }
            }.bind(this))

        }
        

        return res
    }




}
