import {environment} from '../common'

const WEB_CONFIG = {
    api_path:"/app/v1",
    // api_path:"http://192.168.0.104:5002",

    // If this option is enabled, check CORS
    // auth_proxy:"http://192.168.0.104:5002",
}

const NATIVE_CONFIG = {
    // api_path:"http://192.168.0.104:5001/app/v1",
    api_path:"https://osaf.com.ar/app/v1"
    // auth_proxy:"https://kalenislims.com"
}

//TODO: When main server proxy is applied
// const AUTH_PROXY_CONFIG = {
//     api_path:
// }

export const configs = {
    'native':NATIVE_CONFIG,
    'web':WEB_CONFIG,
    
}

export const Config = () => {
    return configs[environment()]

}

