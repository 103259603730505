import BinaryImagePreview from './BinaryImagePreview'
import ColorPicker from './ColorPicker'
import EmptyWidget from './EmptyWidget'

let definitions = {
    'binary':{
        'image':BinaryImagePreview
    },
    'char':{
        'color_picker':ColorPicker
    }
}

const get = (field_type,name) => {
    if(definitions[field_type][name]){
        return definitions[field_type][name]
    }
    else{
        return EmptyWidget
    }
    

}

export const widgets = {
    get
}