import React, {  useState, useEffect } from 'react';
import Portal from '../common/Portal.js'
//import useClickOutside from '../common/useClickOutside.js'



const DropDown = React.forwardRef((props, ref) => (

    <div>
    {props.open &&
    
    <Portal>
    <DropDownP key={'filter_bar'} parent_ref={ref} {...props}> 
        {props.children}
    </DropDownP>

</Portal>
    }
        
    </div>
    
    

));


function DropDownP(props) {
    const [position, setPosition] = useState({})

   //useClickOutside(props.parent_ref, props.onClose, props.open);

    
    useEffect(() => {
      
        const dimensions = props.parent_ref.current.getBoundingClientRect()
        let pos = {
            position: 'absolute',
            left: dimensions.left,
            top: dimensions.top + dimensions.height,
            width: dimensions.width,
            zIndex:'9999'
                }
        
        if(props.modifiers){
            pos.width +=props.modifiers.width || '100%'
            pos.left+= props.modifiers.left || 0
            pos.top += props.modifiers.top || 0
        }
        setPosition(pos)
        


    }, [props.parent_ref]);


    // const wrapperRef = React.useRef(null);

    
   

    return(
        // <Portal>
            
            <div {...props} style={position}>
                {props.children}
            </div>
            
        // </Portal>

    )

}

export default DropDown;


            //             offsetHeight: 52
            // offsetLeft: 24
            // offsetParent: div.w-full.z-50.text-contrast-primary.shadow-md.top-0.right-0.fixed.bg-primary.
            // offsetTop: 6
            // offsetWidth: 600
            // scrollHeight: 52
            // scrollLeft: 0
            // scrollTop: 0
            // scrollWidth: 600