import React, {useEffect} from 'react';
import BaseField from './BaseField.js';
import AsyncSelectField from './AsyncSelectField.js';
import SelectField from './SelectField.js'
import LookUpField from './LookUpField.js';
import FieldWrapper from './FieldWrapper'
import DateField from './DateField'
import BooleanField from './BooleanField'
import O2MField from './O2MField'
import TextField from './TextField'
import HtmlField from './HtmlField'
import BinaryField from './BinaryField'
import FormulaField from './FormulaField'
import FloatField from './FloatField'
import { observer } from 'mobx-react-lite'
import {widgets} from './widgets/widgets'

const NO_WRAP_FIELDS = ['one2many']

const fields = {
    'float': ({ props, value, onChange, handleFieldBlur, state_attrs, onFocus, record }) => <FloatField {...props} value={value} onFocus={onFocus} onBlur={handleFieldBlur} onChange={onChange} state_attrs={state_attrs} record={record} />,
    'char': ({ props, value, onChange, handleFieldBlur, state_attrs, onFocus }) => <BaseField {...props} value={value} onFocus={onFocus} onBlur={handleFieldBlur} onChange={onChange} state_attrs={state_attrs} />,
    'integer': ({ props, value, onChange, handleFieldBlur, state_attrs, onFocus }) => <BaseField {...props} value={value} onFocus={onFocus} onBlur={handleFieldBlur} onChange={onChange} state_attrs={state_attrs} />,
    'text': ({ props, value, onChange, handleFieldBlur, state_attrs, onFocus }) => <TextField {...props} value={value} onBlur={handleFieldBlur} onFocus={onFocus} onChange={onChange} state_attrs={state_attrs} />,
    'date': ({ props, value, onChange, state_attrs, handleFieldBlur }) => <DateField  {...props} value={value} onBlur={handleFieldBlur} state_attrs={state_attrs} onChange={onChange} />,
    'many2one': ({ props, save, state_attrs, handleFieldBlur }) => <AsyncSelectField saveOnBlur={save} state_attrs={state_attrs} onBlur={handleFieldBlur}   {...props} />,
    'selection': ({ props, onChange, state_attrs, handleFieldBlur }) => <SelectField onChange={onChange} state_attrs={state_attrs} onBlur={handleFieldBlur} {...props} />,
    'boolean': ({ props, state_attrs, handleFieldBlur }) => <BooleanField onBlur={handleFieldBlur} {...props} />,
    'multi_selection': ({ props, onChange, state_attrs, handleFieldBlur }) => <SelectField onChange={onChange} multi={true} onBlur={handleFieldBlur} state_attrs={state_attrs}  {...props} />,
    'one2many': ({ props, handleInnerOnChange, state_attrs }) => <O2MField {...props} state_attrs={state_attrs} onChange={handleInnerOnChange} />,
    'multi_link': ({ props, save, state_attrs, handleFieldBlur }) => <AsyncSelectField saveOnBlur={save} state_attrs={state_attrs} onBlur={handleFieldBlur}  {...props} multi={true} />,
    'datetime': ({ props, value, onChange, state_attrs, handleFieldBlur }) => <DateField  {...props} value={value} state_attrs={state_attrs} onBlur={handleFieldBlur} onChange={onChange} />,
    'lookup':({ props, value, onChange, handleFieldBlur, state_attrs }) => <LookUpField {...props} value={value} onBlur={handleFieldBlur} onChange={onChange} state_attrs={state_attrs} />,
    'html': ({ props, value, onChange, handleFieldBlur, state_attrs }) => <HtmlField {...props} value={value} onBlur={handleFieldBlur} onChange={onChange} state_attrs={state_attrs} />,
    'binary':({ props, value, onChange, handleFieldBlur, state_attrs }) => <BinaryField {...props} value={value} onBlur={handleFieldBlur} onChange={onChange} state_attrs={state_attrs} />,
    'formula':({ props, value, onChange, handleFieldBlur, state_attrs, record }) => <FormulaField {...props} value={value}>{<>{fields[props.field.formula_field_widget]({ props, value, onChange, state_attrs, handleFieldBlur, record })}</>}</FormulaField>,



}
const Field = observer(function Field(props) {
    // function Field(props) {

    const { field, record } = props
    // const value = record._values[field.name]
    const value = field.get_value(record)
    // const error = record._errors[field.name]
    const state_attrs = field.get_state_attrs(record) || {}
    
    // const state_attrs = record.state_attrs[field.name] || {}
    // HERE => Use a cleanup hook when unmount for remove the fieldInEdition Prop
    function onChange(e, value) {
        // record.setFieldInEdition(field.name)
        record.setFieldInEdition(field.name)
        return field.set_value(value, record)
    }
    useEffect(() => {
        
        return function cleanup() {
            
            record.setFieldInEdition(false)
            
            
        };
      },[]);

    function onBlur(e,new_value){
        
        record.setFieldInEdition(false)
        return field.custom_on_change({value:new_value,record:record})
    }

    function onFocus(e){
        // record.setFieldInEdition(field.name)
        // field.set_edition(record)
        
    }
    


    const getField = () => {
        if (state_attrs.invisible) {
            return (<></>)
        }
        const type = field.type
        if(field.attributes.widget){
            return (<>{widgets.get(type, field.attributes.widget)({ ...props, value, onChange, state_attrs, onFocus, handleFieldBlur:onBlur, record })}</>)
        }
        
        if (fields[type]) {

            return (<>{fields[type]({ props, value, onChange, state_attrs, onFocus, handleFieldBlur:onBlur, record })}</>)
        }
    }

    return (
        <>
            {props.noWrap || NO_WRAP_FIELDS.includes(field.type) ?
                <>
                    {getField()}

                </>
                :
                <FieldWrapper state_attrs={state_attrs} value={value} style={{ display: state_attrs.invisible ? 'none' : '' }} {...props}>
                    {getField()}


                </FieldWrapper>
            }
        </>



    )

});

export default Field;