import React, { useState, useContext, useEffect, useReducer } from 'react';
import FormView from './form/FormView.js'
import BoardView from './board/BoardView.js'
import GraphView from './graph/GraphView.js';
import SpreadSheet from './spreadsheet/Spreadsheet.js'
import HtmlView from './html/HtmlView.js'
import CustomLoader from './custom/CustomLoader.js'
import ModalView from './ModalView';
import CardList from './card_list/CardList'
// import moment from 'moment';

import { AppContext } from 'kalenis-portal-frontend-main'
import { observer } from 'mobx-react-lite'
import {

    useLocation,
    useHistory

} from "react-router-dom";

import { extractFilters } from '../common/common.js';
import CalendarView from './calendar/CalendarView.js';

export function CreateView(props) {
    const app_context = useContext(AppContext)
    // const notifications = useContext(NotificationContext)
    // notifications.addSnack({message:"View is mounted right now",timeout:1000})
    const history = useHistory()


    function useQuery() {

        return new URLSearchParams(useLocation().search);
    }
    let queryParams = useQuery()
    const initial_search = extractFilters(queryParams)
    const route_state = history.location.state || {}
    const screen = app_context.addScreen(props.view, history.push, initial_search, route_state)


    return (


        <View_ screen={screen} navigate={props.navigate}/>



    )
}

export const View_ = observer(function View_(props) {
    const type = props.screen.type
    const loadingFields = props.screen.loadingFields
    const modal_childs = props.screen.modal_childs.screens || {}
    const { container, app_context } = props;
    
    return (
        <>
            {!loadingFields &&
                <React.Fragment>
                    {type === "list" || type === "spreadsheet" ?
                        <>
                            {props.screen.fields.length > 0 &&
                                <>
                                    {props.screen.list_type == 'card_list' ?
                                        <CardList
                                            screen={props.screen}
                                            height={props.height}
                                            headless={props.headless}
                                            hide_toolbar={props.hide_toolbar}
                                            container={container}
                                            
                                        />
                                        :
                                        <SpreadSheet
                                            screen={props.screen}
                                            height={props.height}
                                            headless={props.headless}
                                            hide_toolbar={props.hide_toolbar}
                                            container={container}
                                            

                                        />
                                    }

                                </>
                            }
                        </>

                        : type === "form" ?

                            <FormView
                                screen={props.screen}
                                containerStyle={props.containerStyle || {}}
                                container={container}

                            />
                            :
                             type === "calendar" ?

                            <CalendarView
                                screen={props.screen}
                                containerStyle={props.containerStyle || {}}
                                container={container}

                            />
                            :
                            type === "html" ?
                                <HtmlView
                                    screen={props.screen}
                                    height={props.height}
                                    container={container}
                                // view={props.view}
                                // widgets={widgets}
                                />
                                :
                                type === "board" ?
                                    <BoardView
                                        screen={props.screen}
                                        container={container}
                                    // view={props.view}
                                    // widgets={widgets}
                                    />
                                    : type === "graph" ?
                                        <GraphView
                                            screen={props.screen}
                                            height={props.height}
                                            hide_toolbar={props.hide_toolbar}
                                            container={container}
                                        // header={{ title: !props.headless ? title : "" }}
                                        // executeAction={executeAction}
                                        // dispatchSearch={dispatchSearch}
                                        // container={props.container || 'paper'}
                                        // view={props.view}
                                        // height={props.height}
                                        // data={data}
                                        // fields={allFields}
                                        // actions={actions}
                                        // header_props={props.header_props}
                                        />
                                        : type === "custom" ?

                                            <CustomLoader
                                                screen={props.screen}
                                                hide_toolbar={props.hide_toolbar}
                                                container={container}
                                            // component={props.view.custom_view_name}
                                            // title={title}
                                            // actions={actions}
                                            // fields={fields}
                                            // data={data}
                                            // noData={noData}
                                            // loading={loading}
                                            // executeAction={executeAction}
                                            // dispatchSearch={dispatchSearch}
                                            // height={props.height}
                                            // container={props.container || 'paper'}
                                            // header_props={props.header_props}

                                            />

                                            :

                                            <div>Not supported view</div>



                    }
                    <>
                        {Object.keys(modal_childs).map((s, i) => (
                            <ModalView
                                key={i}
                                open={true}
                                screen={modal_childs[s]}
                            />


                        ))}
                    </>




                </React.Fragment>
            }


        </>
    )

}
)
