import React, { useMemo, useState, useEffect, useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react';
import './html_field.css'


function HtmlField(props) {
  const { field, state_attrs, value } = props;
  const editorRef = useRef(null);
  // const editor = useMemo(() => withReact(createEditor()), [])

  function onBlurEditor(event, editor) {
    if (!editor) {
      editor = editorRef.current.editor
    }
    if (editor.isDirty()) {
      let body = editor.getContent()
      console.log("THE BODY VALUE")
      console.log(body)
      props.onChange(false, body)
      editor.setDirty(false)
    }


  }


  return (

   
      
      <Editor
        onInit={(evt, editor) => editorRef.current = editor}
        initialValue={value}
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        init={{
          height: 700,
          menubar: false,
          branding: false,
          elementpath: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help autoresize'
          ],
          toolbar: state_attrs.readonly ? false:'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
        onBlur={onBlurEditor}
        //disabled={state_attrs.readonly}
        disabled={props.previewEditor ? props.previewEditor : state_attrs.readonly}
        

      />
      
      
   




  )

}

export default HtmlField;