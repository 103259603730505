import { action, makeObservable, observable } from "mobx"

class EditorConfig {
    initialized = false
    view_definitions = {}
    
    constructor(connection) {
        
        this.connection = connection
        makeObservable(this,{
            view_definitions:observable,
            initialized:observable,
            set_initialized:action,
            set_view_definitions:action,
            
        })   
        this.initialize()  

    }

    async initialize(){
        await this.loadViewDefinitions()
        this.set_initialized(true)
        
    }
    set_initialized(val){
        this.initialized = val;
    }
    set_view_definitions(definitions){
        this.view_definitions = definitions;
    }

    async loadViewDefinitions(){
        const abortController = new AbortController();
        let definitions = await this.connection.dispatch('GET', '/view_handler/editor_definitions', {}, false, true, false, abortController)
        this.set_view_definitions(definitions)
        
    }

    // type: one of ['fields']
    get_definition(type){
        let definition = false
        if(this.view_definitions[type]){
            definition = this.view_definitions[type]
        }
        return definition
    }


   

    



}

export default EditorConfig;