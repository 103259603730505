import React from "react";
import { ReactComponent as DeleteIcon } from "./icons/svg/close.svg";
import IconButton from "./buttons/IconButton";
import { observer } from 'mobx-react-lite'

const FilterChip = observer(function FilterChip(props) {
  const { filter, record, removeFilter } = props;
  const description = filter.field.description
  const operator_label = filter.operator ? filter.operator.label:false
  const value = filter.field ? filter.field.get_string_value(record):false
  const valid = (description && operator_label && value)

  return (
    <>
      {valid &&
        <div className="cursor-default flex mr-1 rounded bg-white border border-gray-300 text-gray-600 font-semibold ">
          <div className="flex m-2">
            <p >{description}</p>
            <p className="mx-1">|</p>
            <p>{operator_label}</p>
            <p className="mx-1">|</p>
            <p className="text-primary">{value}</p>
          </div>
          {!filter.required && (
            <IconButton color="warning">
              <DeleteIcon className="fill-current h-3"
                onClick={() => {
                  removeFilter(filter);
                }}
              />
            </IconButton>
          )}
        </div>
      }

    </>
    )

  }
  )

export const FiltersActive = observer(function FiltersActive(props) {
  const { filters } = props;
  const filtersActive = filters.active_filters;
  const record = filters.record;

  function removeFilter(filter) {
    filters.removeFilter(filter)
    return filters.search()
  }

  return filtersActive.length > 0 ? (
    <div className="text-left mb-2   text-xs">
      <div className="flex">
        {filtersActive.map((filter, index) => (
          <>
            <FilterChip
              filter={filter}
              record={record}
              removeFilter={removeFilter}
              key={index}
            />

          </>
        ))}
      </div>
    </div>
  ) : (
    ""
    )

  }
  )


// toogleFilters() en FilterBar cierre al hacer clic fuera de DropDown 