import { makeAutoObservable, computed, action, runInAction, observable, makeObservable } from "mobx"
import { createField } from '../model/Field'
import { Action } from '../model/Action'
import { RecordGroup, Record } from '../model/Record'
import { Filters } from '../model/Filters'
import {ScreenEditor} from './ScreenEditor'

const GENERAL_TYPE_ACTIONS = ['export_data']


export class Screen {
    view
    id
    id_field
    type
    fields = []
    actions = []
    records = []
    current_search = []
    order = []
    limit = null
    // pagination_enabled = false
    // default_page_size = 0
    // page_size_options = []
    isLoading = true
    loadingFields = true
    loadingInitialData = true
    loadingData = false
    headless = false
    action_id = ""
    action_params = []
    active_record = false
    filters = []
    parent = false
    field_childs = {}
    modal_childs = {}
    modal = false
    parent = false
    data = {}
    actions_map = {}
    mobile_record_title = ""
    mobile_record_header_image = ""
    auto_save = true
    start_empty = false
    style_modifiers = {
        'styles': {},
        'classnames': {}
    }
    view_template = ""
    default_filters = []
    default_filters_open = false
    screen_editor_access = {}
    fileHandler = false
    no_data_message = ""
    add_record_button = false
    readonly = false


    get visible_fields() {
        const vf = this.fields.filter(field => field.visible)
        //needs review
        if (vf[0] !== "selector" && this.type === 'list' || this.type === 'spreadsheet') {
            vf.unshift("selector")
        }
        return vf
    }
    get filterable_fields() {
        const vf = this.fields.filter(field => field.searchable)
        //needs review

        return vf
    }
    get conditional_style_fields() {
        const fields = this.fields.filter(field => field.conditional_style)
        

        return fields
    }
    get field_names() {
        return this.fields.map(function (f) { return f.name })
    }
    get initialized() {
        return !this.loadingFields && !this.data.loadingInitialData
    }
    get visible_actions(){
        return this.actions.filter(function (act) { return act.invisible == false })
        
    }
    get link_actions() {

        return this.visible_actions.filter(function (act) { return act.type === 'link' })
    }
    get report_actions() {

        return this.visible_actions.filter(function (act) { return act.type === 'report' })
    }
    get action_actions() {

        return this.visible_actions.filter(function (act) { return act.type === 'action' })
    }
    get view_group_actions() {

        return this.visible_actions.filter(function (act) { return act.type === 'view_group' })
    }
    get button_actions() {
        return this.actions.filter(function (act) { return act.show_button === true })
    }
    get all_menu_actions() {
        return this.visible_actions.filter(function (act) { return act.show_button === false })
    }
    get default_action() {
        return this.actions.find(a => a.default === true)
    }
    get default_save_action() {
        return this.actions.find(a => a.default_save === true)
    }
    get delete_action() {
        const delete_actions = this.actions.filter(function (act) { return act.type === 'delete_record' })
        if (delete_actions.length === 1) {
            return delete_actions[0]
        }
        else if (delete_actions.length > 1) {
            throw new Error("Only one delete action its allowed")
        }
        else {
            return false
        }

    }
    get general_actions() {

        return this.actions.filter(function (act) { return GENERAL_TYPE_ACTIONS.includes(act.type) })
    }

    get search_state() {
        return {
            current_search: this.current_search,
            action_params: this.action_params,
            action_id: this.action_id,
            order: this.order
        }
    }

    get notifications() {
        return this.connection.notifications
    }
    get mobile_primary_fields() {
        return this.visible_fields.filter(function (f) { return f.mobile_available === 'primary' })
    }
    get mobile_secondary_fields() {
        return this.visible_fields.filter(function (f) { return f.mobile_available === 'secondary' })
    }
    get mobile_record_image_header_field() {
        return this.fields.find(f => f.name === this.mobile_record_header_image)
    }


    constructor(group, view, connection, navigate, initial_search, route_state, is_modal, parent, initialize_fields = true, initialize_actions = true, initialize_data=true, initialize_callback=false, fileHandler=false) {


        this.view = view
        this.initialize_data = initialize_data
        this.initialize_fields = initialize_fields
        this.initialize_actions = initialize_actions
        this.title = (route_state && route_state.title) ? route_state.title : view.title
        this.id = view.id
        this.type = view.type
        this.connection = connection
        this.ordered_column = { name: false, order: false }
        this.id_field = view.id_field ? view.id_field : "id"
        this.filterable = view.filterable
        this.navigate = navigate
        this.data_origin = view.data_origin
        this.no_data_message = view.no_data_message
        this.group = group
        
        
        if(group && this.group.createGroup){
            this.field_childs = group.createGroup(connection)
            // this.modal_childs = new ScreenGroup(connection)
            this.modal_childs = group.createGroup(connection)
        }
        else{
            this.field_childs = []
            this.modal_childs = []
        }
        

        this.data = new RecordGroup(
            connection,
            {
                'pagination_enabled': view.pagination_enabled,
                'default_page_size': view.default_page_size,
                'page_size_options': view.page_size_options,
                'show_records_count': view.show_records_count
            },
            this)
        this.modal = is_modal
        this.parent = parent
        this.mobile_record_title = view.mobile_record_title
        this.mobile_record_header_image = view.mobile_record_header_image
        this.start_empty = view.start_empty
        this.view_template = view.view_template
        this.default_filters = view.default_filters || []
        this.default_filters_open = view.default_filters_open
        this.initialize_callback = initialize_callback
        this.editor = new ScreenEditor(this.connection.editor_config, this, view.view_edition_access)
        this.fileHandler = fileHandler
        this.readonly = false


        makeObservable(this, {
            //computed
            visible_fields: computed,
            initialized: computed,
            link_actions: computed,
            filterable_fields: computed,
            search_state: computed,
            //observables
            fields: observable,
            
            actions: observable,
            current_search: observable,
            action_id: observable,
            action_params: observable,
            active_record: observable,
            ordered_column:observable,
            filters: observable,
            order: observable,
            data: observable,
            modal: observable,
            loadingFields: observable,
            style_modifiers: observable,
            title:observable,
            editor:observable,
            fileHandler:observable,
            add_record_button:observable,
            readonly:observable,
            //actions
            new_record: action,
            set_title: action,
            set_actions_map: action,
            set_limit: action,
            add_classname: action,
            add_style: action,
            set_ordered_column:action,
            set_active_record:action,
            reset_fields:action,
            set_file_handler:action,
            set_add_record_button:action,
            set_readonly:action


        })


        // this.loadFields()
        this.initialize(initial_search)
        this.filters = view.filterable ? new Filters({}, this) : false
        // this.do_search(initial_search)
        this.loadActions()
        




    }

    async initialize(search) {
        await this.loadFields()
        if (!this.start_empty && this.initialize_data) {
            await this.do_search(search)


        }
        else {
            this.data.setInitialLoading(false)
            this.data.setLoading(false)
            if (this.filterable) {
                this.filters.setInitialFilters()
            }



        }
        // if(this.filterable){
        //     this.filters.setDefaultFilters()
        // }
        if(this.initialize_callback){
            this.initialize_callback(this)
        }


    }

    set_file_handler(value){
        this.fileHandler = value;
    }
    async reload_field_childs() {
        if (this.active_record.id > 0) {
            this.data.o2m_fields.forEach(function (field) {
                field.initialize(this.active_record, true)
            }.bind(this))
        }

    }

    async reload_parent() {
        await this.parent.do_search()
        await this.parent.reload_field_childs()
    }
    //value: {text,color,execute:()=>{}}
    set_add_record_button(value){
        this.add_record_button = value
    }
    
    //value: function to determinate if the screen is readonly
    set_readonly(value){
        this.readonly = value;
    }

    async do_search(search, force) {
        let count = false
        let reset_page = false
        if (search) {
            // this.search = search
            this.current_search = search.current_search || this.current_search
            this.action_params = search.action_params || this.action_params
            this.action_id = search.action_id || false
            this.order = search.order || this.order
            //count records and reset page in every search change
            count = true
            reset_page = true
        }
        if (this.filterable) {
            this.filters.setInitialFilters()
        }



        this.data.loadData(count, reset_page)

    }
    /**
* Set Limit. 
* @param {int} value - limit value
* @return {void} 
    */

    set_limit(value) {
        this.data.setLimit(value)
        // this.do_search()
    }

set_ordered_column(fname,direction){
    this.ordered_column = {name:fname,order:direction}
    this.order = [[fname, direction]]
}

    /**
* Set search order and dispatch search. 
* @param {string} field_name - field name to order by
* @param {string} direction - one of [ASC,DESC]. If undefined, the current direction will be toogled
* @param {boolean} dispatch - If true, a search with the new order will be dispatched.
* @param {boolean} navigate - If false, the action will not navigate (used on mobile and headless web views)
* @return {void} 
*/
    async set_order(field_name, direction, dispatch=true, navigate=true) {
        const search = { ...this.search_state }

        direction = direction ? direction : this.ordered_column.order === 'ASC' ? 'DESC' : 'ASC'
        search['order'] = [[field_name, direction]]
        //TODO: Review args on create url. currently receives search instead of current_search
        search['search'] = search.current_search
        
        this.set_ordered_column(field_name,direction)
        if(dispatch){
            if(navigate){
                let url = this.connection.createUrl(search)
            
                this.navigate(url)
            }
            else{
                this.do_search(search)
            }

        }
        
        

    }

    set_title(title) {

        this.title = title ? title : this.title
    }
    set_active_record(record) {


        this.active_record = record;

    }



    get_record_by_index(index) {
        return this.data.get_record_by_index(index)
    }
    get_record_by_id(id) {
        return this.data.get_record_by_id(id)
    }
    get_field_by_name(fname){
        return this.fields.find(f => f.name == fname)
    }


    new_record(values) {
        const new_record = new Record(values, this);
        this.records.push(new_record)
        this.set_active_record(this.records[this.records.indexOf(new_record)])
    }

    createFields(fields) {
        this.fields = fields.map(function (f) {
            return createField(f, this)
        }.bind(this))
    }

    reset_fields(){
        alert("reset fields")
        this.fields = []
    }

    async loadFields() {
        if (!this.initialize_fields) {
            return false
        }
        let args = { view_id: this.view.id }
        const abortController = new AbortController();
        let all_fields = await this.connection.dispatch('GET', '/fields', args, false, false, false, abortController)

        runInAction(() => {
            this.createFields(all_fields)
            this.loadingFields = false;
            // add filters after fields are loaded


        })



    }

    createActions(actions) {
        this.actions = actions.map(function (a) {
            return new Action(a, this)
        }.bind(this))
    }
    set_actions_map(actions_map) {
        this.actions_map = actions_map
    }

    async loadActions() {
        if (!this.initialize_actions) {
            return false
        }
        let args = { view_id: this.view.id }
        const abortController = new AbortController();
        let new_actions = await this.connection.dispatch('GET', '/actions', args, false, true, false, abortController)


        runInAction(() => {
            this.createActions(new_actions)
        })


    }
    /**
* Add style to be used by screen components. 
* Supported Components:
* * Modal
* @param {object} style - object with component as key and style to apply as value
* @return {void} 
*/
    add_style(style) {
        this.style_modifiers.styles = { ...this.style_modifiers.styles, ...style }

    }
    /**
* Add classname to be used by screen components. 
* Supported Components:
* * Modal
* @param {object} classname - object with component as key and classname(str) to apply as value
* @return {void} 
*/
    add_classname(classname) {

        this.style_modifiers.classnames = { ...this.style_modifiers.classnames, ...classname }
    }



}

