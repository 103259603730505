import {Action} from '../../model/Action'
import { v4 as uuidv4 } from 'uuid';
import { makeObservable,observable, action,runInAction } from 'mobx';
import { environment } from '../../common'

class BoardWidget {
    type = ""
    id = ""
    name = ""
    colspan = false
    constructor(attributes,parent){
        this.type = attributes.type
        this.name = attributes.name
        this.parent = parent
        this.id = attributes.id
        this.colspan = attributes.colspan ? parseInt(attributes.colspan):false
    }
}

class ViewWidget extends BoardWidget{
    view_definition = ""
    screen = ""
    height = ""
    // colspan = 4

    constructor(attributes,parent){
        super(attributes,parent)
        this.view_definition = attributes.widget_view
        this.view_definition.start_empty = true;
        this.height = attributes.height ? parseFloat(attributes.height): 0.4
        // this.id = attributes.widget_view.id
        this.parent = parent
        this.screen =  this.parent.child_screens.addScreen(this.view_definition, false, { current_search: [] }, {}, false)
        this.screen.filterable = false;
        this.screen.parent = this.parent
        this.colspan = this.colspan ? this.colspan:4
    }
}

class IndicatorWidget{
    constructor(attributes,screen){

    }
}
class ActionWidget extends BoardWidget{
    action = ""
    image = ""
    icon_name = ""
    text_color = ""
    icon_color = ""
    background_color = ""
    
    constructor(attributes,parent){
        super(attributes,parent)
        if(attributes['action']){
            this.action = new Action(attributes['action'], parent)
        }
        this.icon_name = attributes['icon_name']
        this.text_color = attributes['text_color']
        this.icon_color = attributes['icon_color']
        this.background_color = attributes['background_color']
        this.colspan = this.colspan ? this.colspan:2
        makeObservable(this,{
            image:observable,
            icon_name:observable,
            getImage:action,
        })
        this.getImage()
        
    }

    async getImage(){
        let args = {
            'widget_id':this.id
        }
        const abortController = new AbortController();
        let image = await this.parent.connection.dispatch('GET', '/widget_image', args, false, true, true, abortController)
        if (environment() === 'web') {
            image = URL.createObjectURL(image)
        }
        else {
            var reader = new FileReader();
            reader.readAsDataURL(image.data);
            reader.onloadend = function () {
                var base64data = reader.result;
                image = base64data
            }

        }
        runInAction(() => {
            this.image = image
            
            


        })
        
    }
}

export const BoardWidgets = {
    'view': ViewWidget,
    'indicator':IndicatorWidget,
    'action':ActionWidget
}
