import { makeObservable, action, observable } from 'mobx'
import { Screen } from '../screen/Screen'

export class ResetPassword {
    screen=""
    connection=""
    url=""
    navigate = ()=>{}
    constructor(connection, navigate){
        makeObservable(this,{
            setScreen:action,
            screen:observable,
            setUrl:action
        })
        this.connection = connection
        this.navigate = navigate
        this.getView()
        

    }

    createScreen(definition){
        let screen = new Screen({}, definition.view, this.connection,false, [], {},false, false,false,false)
        screen.createFields(definition.fields)
        screen.createActions(definition.actions)
        screen.loadingFields= false
        
        return screen
    }
    setUrl(url){
        this.url = url
    }

    setScreen(definition){
        let screen = this.createScreen(definition)
        let record = screen.data.addRecord({values:{'id':-1}})
        screen.set_active_record(record)
        screen.data.loadingInitialData = false
        screen.data.loading = false

        //Map 'reset_password' action to resetPassword method
        screen.set_actions_map({
            'reset_password': (records)=>{this.setPassword(records)}
        })
        this.screen = screen

        

    }

    async getView(){
        const abortController = new AbortController();
        let view_definition = await this.connection.dispatch('GET', '/reset_password_view', {}, false, true, false, abortController)
        if(view_definition){
            this.setScreen(view_definition)    
        }
        
    }

    validatePassword(record){
        let valid = true
        let password = record._values['new_password']
        let confirm = record._values['repeat_password']
        if(!password || !confirm){
            return false
        }
        else if (password !== confirm){
            valid = false
        }
        return valid
    }

   
    async setPassword(records){
        
        if(!records[0]){
            return 
        }

        const record = records[0]
        if(!this.validatePassword(record)){
            alert("Las contraseñas no coinciden")
            return
        }
        let args = {}
        args['url'] = this.url
        args['password'] = record._values['new_password']
        const abortController = new AbortController();

        const goToLogin = ()=> {
            this.navigate('/login')
            return true
        }

        let res = await this.screen.connection.dispatch('POST', '/set_password_url', args, false, false, false, abortController)
        
        if(res['status']){
            this.screen.notifications.addDialog({'message':"Contraseña Recuperada con éxito.", 'actions':[{'name':'OK', 'color':'primary', 'callback':goToLogin}]})    
        }
        



    }

    

    


}