import React, { useState, useEffect, useRef } from 'react';

import 'react-virtualized/styles.css';

import Container from '../../ui/surfaces/Container.js'
import clsx from 'clsx';
import ViewHeader from '../ViewHeader.js';
import LoadingIndicator from '../../common/LoadingIndicator'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import './calendar_styles.css';
import { observer } from 'mobx-react-lite'
import moment from 'moment'

import { Calendar, momentLocalizer } from 'react-big-calendar'
import Toolbar from "react-big-calendar/lib/Toolbar";



const CalendarView = observer(function CalendarView(props) {
    const screen = props.screen
    const data = screen.data.records
    const localizer = momentLocalizer(moment)
    const height = props.height ? props.height : (window.innerHeight * 90) / 100;
    const view_types = screen.view_types
    

    
  

    function onRangeChange(range){
        console.log("OnView changing")
        console.log(range)
        // Maybe usefull for calculate month displayed, another option is to fetch month -1 and month +1
        console.log("LOCALIZZER")
        console.log(localizer.visibleDays(new Date()))
    }
    
    

    return (
        <div style={{height}} className=" flex justify-center w-full bg-white ">
            <Calendar
                selectable
                popup
                events={data}
                views={view_types}
                defaultView={screen.initial_view}
                step={60}
                showMultiDayTimes
                titleAccessor={(event) => {return screen.get_title_value(event)}}
                startAccessor={(event) => {return screen.get_start_date_value(event)}}
                endAccessor={(event) => {return screen.get_end_date_value(event)}}
                allDayAccessor={(event) => {return screen.all_day_events}}
                onSelectEvent={(event) => {screen.view_event_detail([event])}}
                onSelectSlot={(info)=> {screen.new_event(info)}}
                localizer={localizer}
                onView={onRangeChange}
                // components={{toolbar: InitialRangeChangeToolbar}}
            />


        </div>


    )

}
)


export default CalendarView;