import React from 'react';

function ColorPicker(props) {
    const { field, state_attrs, record } = props;
    
    return (
        <div>  
            <div className="text-red-600">Implementation Pending</div>       
        </div>





    )

        }


export default ColorPicker;